import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ProjectHeader } from '../../../components/project-header';
import {
  useEditEventMutation,
  useGetCurrentEventQuery
} from '../../../api/events-api';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import type { HeaderItem } from '../../../components/data-table';
import { DataTable } from '../../../components/data-table';
import { useScrollHeight } from '../../../hooks/use-scroll-height';
import { useAppDispatch, useTypedSelector } from '../../../api/store';
import {
  setContactInEventColumns,
  setPhoneSearchTerm
} from '../../../api/page-data-slice';
import {
  useChangeCommonStepMutation,
  useChangeSatisfactionMutation,
  useContactsDelegateMutation,
  useGetContactsQuery
} from '../../../api/contact-api';
import { useFilter } from '../../../hooks/use-filter';
import { useDateFilter } from '../../../hooks/use-date-filter';
import { SubHeader } from '../../../components/subheader';
import { FlexContainer } from '../../../components/flex-container';
import { PageTitle } from '../../../components/page-title';
import { Counter } from '../../../components/counter';
import { SubheaderSelect } from '../../../components/subheader-select';
import { CalendarSvg } from '../../../components/svg/calendar-svg';
import { DateRange } from '../../../components/date-range';
import { EditColumns } from '../../../components/edit-colums';
import { FilterSvg } from '../../../components/svg/filter-svg';
import { Filters } from '../../../components/filters';
import { useNotification } from '../../../hooks/use-notification';
import { dateToApiFormat } from '../../../helpers/date-helpers';
import { getApiError } from '../../../helpers/get-api-error';
import { ConfirmPopup } from '../../../components/confirm-popup';
import { SwitcherKanban } from '../../../components/switcher-kanban';
import { BtnPrimary } from '../../../components/btn-primary';
import { useGetUserMeQuery } from '../../../api/user-api';
import { MailingListPopup } from '../../../components/mailing-list-popup';
import { isFieldAllowed } from '../../../helpers/is-field-allowed';
import { checkIsManager } from '../../../helpers/is-manager';
import { Delegate } from '../delegate';
import { ChangeStep } from '../cahnge-step';
import { NoData } from '../../../components/no-data';
import { ChangeSatisfaction } from '../cahnge-satisfaction';
import { initialFilters } from './data';
import { EditEvent } from './edit-event';
import { transformEventsData } from './helpers';
import type { CurrentEventsTable } from './types';
import { tableHeaders } from './data';
import { Kanban } from './kanban';

export const CurrentEvent = () => {
  const notification = useNotification();
  const { phoneSearchTerm } = useTypedSelector((state) => state['page-data']);
  const tableRef = useRef(null);
  const { scrollHeight, manuallySetScrollHeight } = useScrollHeight(tableRef);
  const [searchParams, setSearchParams] = useSearchParams();
  const { eventId } = useParams();
  const [delegate] = useContactsDelegateMutation();
  const [changeCommonStep] = useChangeCommonStepMutation();
  const [updateSatisfaction] = useChangeSatisfactionMutation();
  const { data: userMe } = useGetUserMeQuery('');
  const { data: event } = useGetCurrentEventQuery(
    { id: eventId },
    { refetchOnMountOrArgChange: true, skip: !eventId }
  );
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showDelegate, setShowDelegate] = useState(false);
  const [showChangeStep, setShowChangeStep] = useState(false);
  const [showChangeSatisfaction, setShowChangeSatisfaction] = useState(false);
  const {
    filters,
    filtersLength,
    showFilter,
    setShowFilter,
    setFilters,
    badges,
    resetFilters,
    aplyFilter
  } = useFilter({
    initialFilters,
    usersFilterName: 'resp_manager',
    channelsFilterName: 'channels',
    steps: event?.steps,
    setCheckedList
  });
  const {
    showCalendar,
    setShowCalendar,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    applyDate,
    resetDate
  } = useDateFilter({
    startDateName: 'event_added_start',
    endDateName: 'event_added_end',
    setCheckedList
  });
  const [editEvent] = useEditEventMutation();
  const [edit, setEdit] = useState(false);
  const [showMailingList, setShowMailingList] = useState(false);
  const [isKanban, setIsKanban] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { contactInEventColumns, pageSize } = useTypedSelector(
    (state) => state['page-data']
  );
  const {
    data: contacts,
    refetch,
    isFetching,
    isLoading,
    isSuccess
  } = useGetContactsQuery({
    params: `?size=${pageSize}&events=${eventId}&sort_by_event_added=true&phone_search=${encodeURIComponent(
      phoneSearchTerm
    )}&${searchParams.toString()}`
  });
  const [finishEvent, setFinishEvent] = useState(false);

  const setCustomColumns = useCallback(
    (columns: HeaderItem<CurrentEventsTable>[] | null) => {
      dispatch(setContactInEventColumns(columns));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isKanban) {
      refetch();
    }
  }, [isKanban, refetch]);

  useEffect(() => {
    if (isSuccess) {
      manuallySetScrollHeight();
    }
  }, [isSuccess, manuallySetScrollHeight, dispatch]);

  useEffect(
    () => () => {
      dispatch(setPhoneSearchTerm(''));
    },
    [dispatch]
  );

  useEffect(() => {
    setCheckedList([]);
  }, [phoneSearchTerm]);

  const resetCustomColumns = useCallback(() => {
    dispatch(setContactInEventColumns(null));
  }, [dispatch]);

  const handleFinishEvent = () => {
    editEvent({
      id: event?.id || '',
      body: { finished: true, end_date: dateToApiFormat(new Date()) }
    })
      .unwrap()
      .then(() => {
        notification({
          title: 'Подію завершено!',
          message: 'Ви успішно завершили подію.',
          type: 'success'
        });
        navigate(`${AppRoute.PROJECTS}/${event?.project.id}`);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  const handleDelegate = ({ manager }: { manager: string | number }) => {
    if (event) {
      delegate({
        delegate: checkedList.join(','),
        new_owner: manager,
        to_project: event?.project.id,
        to_event: event?.id
      })
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            title: 'Контакти делеговано!',
            message: 'Ви успішно делегували обрані контакти. '
          });
          setShowDelegate(false);
        })
        .catch((err) => {
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          });
        });
    }
  };

  const handleChangeStep = ({ stepId }: { stepId: string | number }) => {
    if (event) {
      changeCommonStep({
        delegate: checkedList.join(','),
        event_step_id: stepId,
        event_id: event?.id
      })
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            title: 'Етап змінено!',
            message: 'Ви успішно змінили етап вибраним контактам.'
          });
          setShowChangeStep(false);
        })
        .catch((err) => {
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          });
        });
    }
  };

  const handleChangeSatisfaction = ({
    satisfaction
  }: {
    satisfaction: string | number;
  }) => {
    if (event) {
      updateSatisfaction({
        contact_ids: checkedList.join(','),
        satisfaction
      })
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            title: 'Зміна рівеня задоволеності контактам',
            message: 'Ви успішно змінили рівень задоволеності'
          });
          setShowChangeSatisfaction(false);
        })
        .catch((err) => {
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          });
        });
    }
  };

  const columns =
    contactInEventColumns?.filter((item) => item.checked) || tableHeaders;
  if (!userMe) {
    return null;
  }

  const canView = userMe.role?.view_event || userMe.is_superuser;
  const canEdit = userMe.role?.change_event || userMe.is_superuser;
  const canViewContacts = userMe.role?.view_contact || userMe.is_superuser;
  const canViewProject = userMe.role?.view_project || userMe.is_superuser;
  const isManager = checkIsManager(userMe);

  if (userMe && !canView) {
    return null;
  }

  return (
    <>
      <ProjectHeader
        title={`Подія “${event?.name || ''}”`}
        idTitle={`ID Події: ${event?.id || ''}`}
        finishTitle="Завершити подію"
        onEdit={() => setEdit(true)}
        onFinish={() => setFinishEvent(true)}
        backLink={
          isManager || !canViewProject
            ? AppRoute.EVENTS
            : `${AppRoute.PROJECTS}/${event?.project.id}`
        }
        canFinish={event && !event.end_date}
        withOutEdit={!canEdit}
        eventId={event?.id}
        eventFinished={!!event?.end_date}
      />
      <SubHeader mt="20px">
        <FlexContainer>
          <PageTitle>Контакти</PageTitle>
          {canViewContacts && (
            <>
              <Counter>{contacts?.count}</Counter>
              <SubheaderSelect
                show={showCalendar}
                setShow={setShowCalendar}
                icon={CalendarSvg}
                title="Дата"
                isActive={!!(startDate || endDate)}
              >
                <DateRange
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  onSubmit={applyDate}
                  onReset={resetDate}
                />
              </SubheaderSelect>
              <SubheaderSelect
                show={showFilter}
                setShow={setShowFilter}
                icon={FilterSvg}
                title={`Розширені фільтри (${filtersLength})`}
                isActive={!!filtersLength}
              >
                <Filters
                  setFilters={setFilters}
                  filters={
                    isKanban
                      ? filters.filter((item) => item.name !== 'current_steps')
                      : filters
                  }
                  setShow={setShowFilter}
                  onCancel={resetFilters}
                  onSubmit={aplyFilter}
                  disabled={!filtersLength && !badges.length}
                />
              </SubheaderSelect>
              <EditColumns<CurrentEventsTable>
                onSubmit={setCustomColumns}
                initialHeaders={tableHeaders.filter((item) =>
                  isFieldAllowed(item.id, userMe)
                )}
                columns={contactInEventColumns}
                onReset={resetCustomColumns}
              />
            </>
          )}
        </FlexContainer>
        {canViewContacts && (
          <FlexContainer>
            <SwitcherKanban
              isRightOption={isKanban}
              setIsRightOptins={setIsKanban}
            />
            {!isManager && (
              <BtnPrimary
                onClick={() =>
                  navigate(
                    `${AppRoute.PROJECTS}${AppRoute.EVENTS}/${eventId}${AppRoute.STATISTICS}`
                  )
                }
                type="button"
                fz="14px"
                p="11px 12px"
              >
                Статистика події
              </BtnPrimary>
            )}
          </FlexContainer>
        )}
      </SubHeader>
      {event && !event.contacts_count ? (
        <NoData subTitle="" />
      ) : (
        canViewContacts && (
          <div ref={tableRef}>
            {isKanban ? (
              <Kanban
                eventId={event?.id || ''}
                stepsCount={1}
                steps={event?.steps || []}
              />
            ) : (
              <DataTable<CurrentEventsTable>
                mt="20px"
                onRowClick={(id) => {
                  searchParams.set('contact_id', `${id}`);
                  setSearchParams(searchParams);
                }}
                customHeight={
                  (contacts?.count || 0) >= pageSize
                    ? scrollHeight - 65
                    : scrollHeight
                }
                actions={
                  isManager
                    ? [
                        {
                          title: 'Додати до розсилки',
                          handler: () => setShowMailingList(true)
                        },
                        {
                          title: 'Змінити етап події',
                          handler: () => setShowChangeStep(true)
                        },
                        {
                          title: 'Додати рівень задоволеності',
                          handler: () => setShowChangeSatisfaction(true)
                        }
                      ]
                    : [
                        {
                          title: 'Додати до розсилки',
                          handler: () => setShowMailingList(true)
                        },
                        {
                          title: 'Делегувати',
                          handler: () => setShowDelegate(true)
                        },
                        {
                          title: 'Змінити етап події',
                          handler: () => setShowChangeStep(true)
                        },
                        {
                          title: 'Додати рівень задоволеності',
                          handler: () => setShowChangeSatisfaction(true)
                        }
                      ]
                }
                count={contacts?.count || 0}
                headers={columns.filter((item) =>
                  isFieldAllowed(item.id, userMe)
                )}
                data={transformEventsData(
                  contacts?.results || [],
                  eventId || ''
                )}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                isFetching={isFetching || isLoading}
                withCheckAllPages
                params={`?events=${eventId}&${searchParams.toString()}`}
              />
            )}
          </div>
        )
      )}
      {event && <EditEvent show={edit} setShow={setEdit} event={event} />}
      <ConfirmPopup
        title={`Чи дійсно бажаєте завершити подію ${event?.name || ''}?`}
        show={finishEvent}
        setShow={setFinishEvent}
        onSubmit={handleFinishEvent}
      />
      {event && (
        <Delegate
          onSubmit={handleDelegate}
          show={showDelegate}
          setShow={setShowDelegate}
          event={event}
        />
      )}
      {event && (
        <ChangeStep
          onSubmit={handleChangeStep}
          show={showChangeStep}
          setShow={setShowChangeStep}
          event={event}
        />
      )}
      <MailingListPopup
        show={showMailingList}
        setShow={setShowMailingList}
        contactIds={checkedList}
      />
      <ChangeSatisfaction
        show={showChangeSatisfaction}
        setShow={setShowChangeSatisfaction}
        onSubmit={handleChangeSatisfaction}
      />
    </>
  );
};
